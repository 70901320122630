<template>
  <v-container fluid class="pt-16 pb-16">
    <v-row no-gutters justify="center">
      <v-col col="12" md="8" lg="8" xl="8">
        <v-row no-gutters align="center" justify="center">
          <v-col cols="auto" class="mt-8 mb-16 text-h5"> 온라인 예약 </v-col>
        </v-row>
        <v-row no-gutters align="center" class="tab_bb tab_bt pt-2 pb-2">
          <v-col cols="4" md="3" lg="3" xl="3" class="text-center">
            진료과목
          </v-col>
          <v-col cols="8" md="9" lg="9" xl="9">
            <v-row no-gutters>
              <v-col cols="12" md="6" lg="6" xl="6" class="pr-1 pl-1 mb-1 mt-1">
                <v-select
                  color="reform_primary"
                  item-color="reform_primary"
                  hide-details
                  dense
                  outlined
                  :items="keywordList"
                  v-model="userData.mediItem"
                >
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
                xl="6"
                class="pr-1 pl-1 mb-1 mt-1"
                v-if="userData.mediItem === '기타'"
              >
                <v-text-field
                  hide-details
                  dense
                  outlined
                  color="reform_primary"
                  v-model="tfVal"
                  placeholder="증상 입력"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="tab_bb pt-2 pb-2">
          <v-col cols="4" md="3" lg="3" xl="3" class="text-center">
            진료여부
          </v-col>
          <v-col cols="8" md="9" lg="9" xl="9">
            <v-row no-gutters>
              <v-col cols="12" md="6" lg="6" xl="6" class="pr-1 pl-1 mb-1 mt-1">
                <v-select
                  color="reform_primary"
                  item-color="reform_primary"
                  hide-details
                  dense
                  outlined
                  :items="['초진', '재진']"
                  v-model="userData.mediYn"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="tab_bb pt-2 pb-2">
          <v-col cols="4" md="3" lg="3" xl="3" class="text-center">
            예약 일시
          </v-col>
          <v-col cols="8" md="9" lg="9" xl="9">
            <v-row no-gutters align="center">
              <v-col cols="12" md="6" lg="6" xl="6" class="pr-1 pl-1">
                <v-date-picker
                  no-title
                  flat
                  color="reform_primary"
                  :weekday-format="dayformat"
                  :month-format="monthformat"
                  :header-date-format="getHeaderTitleMonth"
                  v-model="userData.hopeDt"
                  :min="
                    userData.hopeDt.split('-')[0] +
                    '-' +
                    userData.hopeDt.split('-')[1] +
                    '-' +
                    '01'
                  "
                  :allowed-dates="allowedDates"
                  width="100%"
                  class="mt-4"
                ></v-date-picker>
              </v-col>
              <v-col cols="12" md="6" lg="6" xl="6" class="pr-1 pl-1">
                <v-row no-gutters justify="start">
                  <v-col
                    cols="3"
                    class="pa-1 text-center"
                    v-for="(time, index) in timeTable"
                    :key="index"
                  >
                    <v-btn
                      width="100%"
                      height="100%"
                      class="pt-3 pb-3"
                      elevation="0"
                      small
                      :outlined="ttSelect === index ? false : true"
                      :dark="ttSelect === index ? true : false"
                      :color="ttSelect === index ? 'reform_primary' : ''"
                      tile
                      @click="ttSelect = index"
                    >
                      {{ time }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="tab_bb pt-2 pb-2">
          <v-col cols="4" md="3" lg="3" xl="3" class="text-center">
            이름
          </v-col>
          <v-col cols="8" md="9" lg="9" xl="9">
            <v-text-field
              color="reform_primary"
              hide-details
              dense
              outlined
              v-model="userData.bookNm"
              placeholder="이름을 입력하세요"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="tab_bb pt-2 pb-2">
          <v-col cols="4" md="3" lg="3" xl="3" class="text-center">
            연락처
          </v-col>
          <v-col cols="8" md="9" lg="9" xl="9">
            <v-text-field
              hide-details
              dense
              outlined
              v-model="userData.hpNo"
              placeholder="- 없이 번호만 입력하세요"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="tab_bb pt-2 pb-2">
          <v-col cols="4" md="3" lg="3" xl="3" class="text-center">
            내용
          </v-col>
          <v-col cols="8" md="9" lg="9" xl="9">
            <v-textarea
              hide-details
              color="reform_primary"
              dense
              outlined
              v-model="userData.conts"
              no-resize
              auto-grow
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="tab_bb pt-2 pb-2">
          <v-col cols="4" md="3" lg="3" xl="3" class="text-center">
            마케팅 동의
          </v-col>
          <v-col cols="8" md="9" lg="9" xl="9">
            <v-row no-gutters>
              <v-checkbox
                class="mr-2"
                v-model="userData.smsYn"
                label="SMS 수신동의"
                value="Y"
                color="reform_primary"
              >
              </v-checkbox>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="tab_bb pt-2 pb-2">
          <v-col cols="4" md="3" lg="3" xl="3" class="text-center">
            약관 동의
          </v-col>
          <v-col cols="8" md="9" lg="9" xl="9">
            <v-row no-gutters>
              <v-checkbox
                v-model="userData.pslAgrYn"
                label="개인정보 수집동의"
                value="Y"
                color="reform_primary"
              >
              </v-checkbox>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2 pb-2" justify="center">
          <v-col cols="auto" class="text-center">
            <v-btn
              class="mt-5 pt-3 pb-3"
              elevation="0"
              dark
              width="100px"
              large
              tile
              :color="inputCheck() ? 'reform_primary' : 'black'"
              @click="addBook"
            >
              상담신청
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @closeWindow="popupClose()"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import DefaultPopup from "../modal/DefaultPopup.vue";
export default {
  components: {
    DefaultPopup,
  },
  data: () => ({
    popupSet: {},
    pr: {
      DefaultPopupogoAttchId: null,
      hospNm: "",
      hospIntro: null,
      conConts: null,
      drPicAttchId: null,
      drNm: null,
      drHist: null,
      doctIntro: null,
      addrDtl: null,
      hospTel: null,
      hospFaxNo: null,
      etcConts: null,
      mapAttchId: null,
      picAttchId: null,
      prAttchId: null,
      rsvUrl: null,
      homepage: null,
      drId: null,
      docAddvTitle: null,
      docAddvConts: null,
      etcPage: [
        {
          type: null,
          value: null,
        },
        {
          type: null,
          value: null,
        },
        {
          type: null,
          value: null,
        },
      ],
    },
    keywordList: [],

    userData: {
      drId: "",
      bookNm: "",
      mediItem: "",
      mediYn: "",
      bookDt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      hopeDt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      hopeTm: "",
      hpNo: "",
      conts: "",
      smsYn: "",
      pslAgrYn: "",
    },
    tfVal: "",
    ttSelect: undefined,
    timeTable: [
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
    ],
  }),
  created() {
    this.getPromotionData();
    this.getSearchData();
    this.keywordList.push({ text: "기타" });
  },
  computed: {
    ...mapGetters("promotion", ["GET_PROMOTION"]),
    ...mapGetters("search", ["GET_SEARCH"]),
    ...mapState("basic", ["checkPopup"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("promotion", ["FETCH_PROMOTION_TARGET_UN_AUTH"]),
    ...mapActions("search", ["FETCH_SEARCH_WITH_ID"]),
    ...mapActions("book", ["ADD_BOOK"]),
    async getSearchData() {
      //console.log("this.$route.params.code : ", this.$route.params.code);

      if (
        this.$route.params.code === null ||
        this.$route.params.code === undefined
      )
        this.$route.params.code = "";
      var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!| ]{2,20}$/;

      if (!drIdBoo.test(this.$route.params.code)) {
        this.popupActive("", "데이터 로딩에 실패했습니다");
        return;
      }

      const searchParam = {
        drId: this.$route.params.code,
      };
      try {
        await this.FETCH_SEARCH_WITH_ID(searchParam).then(() => {
          if (this.GET_SEARCH.etcKeyword1 !== "") {
            if (this.GET_SEARCH.etcKeyword1 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword1);
            }
          }
          if (this.GET_SEARCH.etcKeyword2 !== "") {
            if (this.GET_SEARCH.etcKeyword2 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword2);
            }
          }
          if (this.GET_SEARCH.etcKeyword3 !== "") {
            if (this.GET_SEARCH.etcKeyword3 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword3);
            }
          }
          if (this.GET_SEARCH.etcKeyword4 !== "") {
            if (this.GET_SEARCH.etcKeyword4 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword4);
            }
          }
          if (this.GET_SEARCH.etcKeyword5 !== "") {
            if (this.GET_SEARCH.etcKeyword5 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword5);
            }
          }
          if (this.GET_SEARCH.etcKeyword6 !== "") {
            if (this.GET_SEARCH.etcKeyword6 !== null) {
              this.keywordList.push(this.GET_SEARCH.etcKeyword6);
            }
          }

          if (this.GET_SEARCH.operNm1 !== "") {
            if (this.GET_SEARCH.operNm1 !== null) {
              this.keywordList.push(this.GET_SEARCH.operNm1);
            }
          }
          if (this.GET_SEARCH.operNm2 !== "") {
            if (this.GET_SEARCH.operNm2 !== null) {
              this.keywordList.push(this.GET_SEARCH.operNm2);
            }
          }
          if (this.GET_SEARCH.operNm3 !== "") {
            if (this.GET_SEARCH.operNm3 !== null) {
              this.keywordList.push(this.GET_SEARCH.operNm3);
            }
          }

          if (this.GET_SEARCH.diagNm1 !== "") {
            if (this.GET_SEARCH.diagNm1 !== null) {
              this.keywordList.push(this.GET_SEARCH.diagNm1);
            }
          }
          if (this.GET_SEARCH.diagNm2 !== "") {
            if (this.GET_SEARCH.diagNm2 !== null) {
              this.keywordList.push(this.GET_SEARCH.diagNm2);
            }
          }
          if (this.GET_SEARCH.diagNm3 !== "") {
            if (this.GET_SEARCH.diagNm3 !== null) {
              this.keywordList.push(this.GET_SEARCH.diagNm3);
            }
          }

          if (this.GET_SEARCH.equipNm1 !== "") {
            if (this.GET_SEARCH.equipNm1 !== null) {
              this.keywordList.push(this.GET_SEARCH.equipNm1);
            }
          }
          if (this.GET_SEARCH.equipNm2 !== "") {
            if (this.GET_SEARCH.equipNm2 !== null) {
              this.keywordList.push(this.GET_SEARCH.equipNm2);
            }
          }
          if (this.GET_SEARCH.equipNm3 !== "") {
            if (this.GET_SEARCH.equipNm3 !== null) {
              this.keywordList.push(this.GET_SEARCH.equipNm3);
            }
          }

          if (this.GET_SEARCH.symptomNm1 !== "") {
            if (this.GET_SEARCH.symptomNm1 !== null) {
              this.keywordList.push(this.GET_SEARCH.symptomNm1);
            }
          }
          if (this.GET_SEARCH.symptomNm2 !== "") {
            if (this.GET_SEARCH.symptomNm2 !== null) {
              this.keywordList.push(this.GET_SEARCH.symptomNm2);
            }
          }
          if (this.GET_SEARCH.symptomNm3 !== "") {
            if (this.GET_SEARCH.symptomNm3 !== null) {
              this.keywordList.push(this.GET_SEARCH.symptomNm3);
            }
          }
          // console.log("키워드리스트 확인", this.keywordList);
          // console.log("키워드리스트 갯수 확인", this.keywordList.length);
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "회원정보를 서버에서 수신하지 못했습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    async getPromotionData() {
      if (
        this.$route.params.code === null ||
        this.$route.params.code === undefined
      )
        this.$route.params.code = "";
      var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!| ]{2,20}$/;
      if (!drIdBoo.test(this.$route.params.code)) {
        return;
      }
      try {
        await this.FETCH_PROMOTION_TARGET_UN_AUTH(this.$route.params.code).then(
          () => {
            // console.log("찾기 조회정보", this.GET_PROMOTION);
            if (this.GET_PROMOTION.logoAttchId !== null) {
              this.pr.logoAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.logoAttchId;
            }
            if (this.GET_PROMOTION.hospIntro !== null) {
              this.pr.hospIntro = this.GET_PROMOTION.hospIntro;
            }
            if (this.GET_PROMOTION.conConts !== null) {
              this.pr.conConts = this.GET_PROMOTION.conConts;
            }
            if (this.GET_PROMOTION.drPicAttchId !== null) {
              this.pr.drPicAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.drPicAttchId;
            }
            if (this.GET_PROMOTION.drHist !== null) {
              this.pr.drHist = this.GET_PROMOTION.drHist;
            }
            if (this.GET_PROMOTION.doctIntro !== null) {
              this.pr.doctIntro = this.GET_PROMOTION.doctIntro;
            }
            if (this.GET_PROMOTION.hospTel !== null) {
              this.pr.hospTel = this.phoneFomatter(this.GET_PROMOTION.hospTel);
            }
            if (this.GET_PROMOTION.hospFaxNo !== null) {
              this.pr.hospFaxNo = this.phoneFomatter(
                this.GET_PROMOTION.hospFaxNo
              );
            }
            if (this.GET_PROMOTION.etcConts !== null) {
              this.pr.etcConts = this.GET_PROMOTION.etcConts;
            }
            if (this.GET_PROMOTION.mapAttchId !== null) {
              this.pr.mapAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.mapAttchId;
            }
            if (this.GET_PROMOTION.picAttchId !== null) {
              this.pr.picAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.picAttchId;
            }
            if (this.GET_PROMOTION.prAttchId !== null) {
              this.pr.prAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.prAttchId;
            }
            if (this.GET_PROMOTION.rsvUrl !== null) {
              this.pr.rsvUrl = this.GET_PROMOTION.rsvUrl;
            }
            if (this.GET_PROMOTION.homepage !== null) {
              this.pr.homepage = this.GET_PROMOTION.homepage;
            }
            if (
              this.GET_PROMOTION.url1 !== null &&
              this.GET_PROMOTION.url1 !== ""
            ) {
              this.pr.etcPage[0].value = this.GET_PROMOTION.url1;
            }
            if (
              this.GET_PROMOTION.urlNm1 !== null &&
              this.GET_PROMOTION.urlNm1 !== "" &&
              this.GET_PROMOTION.urlNm1 !== "선택안함"
            ) {
              this.pr.etcPage[0].type = this.GET_PROMOTION.urlNm1;
            }
            if (
              this.GET_PROMOTION.url2 !== null &&
              this.GET_PROMOTION.url2 !== ""
            ) {
              this.pr.etcPage[1].value = this.GET_PROMOTION.url2;
            }
            if (
              this.GET_PROMOTION.urlNm2 !== null &&
              this.GET_PROMOTION.urlNm2 !== "" &&
              this.GET_PROMOTION.urlNm2 !== "선택안함"
            ) {
              this.pr.etcPage[1].type = this.GET_PROMOTION.urlNm2;
            }
            if (
              this.GET_PROMOTION.url3 !== null &&
              this.GET_PROMOTION.url3 !== ""
            ) {
              this.pr.etcPage[2].value = this.GET_PROMOTION.url3;
            }
            if (
              this.GET_PROMOTION.urlNm3 !== null &&
              this.GET_PROMOTION.urlNm3 !== "" &&
              this.GET_PROMOTION.urlNm3 !== "선택안함"
            ) {
              this.pr.etcPage[2].type = this.GET_PROMOTION.urlNm3;
            }
            if (
              this.GET_PROMOTION.docAddvTitle !== null &&
              this.GET_PROMOTION.docAddvTitle !== ""
            ) {
              this.pr.docAddvTitle = this.GET_PROMOTION.docAddvTitle;
            }
            if (
              this.GET_PROMOTION.docAddvConts !== null &&
              this.GET_PROMOTION.docAddvConts !== ""
            ) {
              this.pr.docAddvConts = this.GET_PROMOTION.docAddvConts;
            }
          }
        );
      } catch (error) {
        console.log("error:", error);
      }
    },
    allowedDates: (val) =>
      val >=
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    dayformat(date) {
      const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    monthformat(date) {
      const monthName = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ];

      let i = new Date(date).getMonth(date);
      return monthName[i];
    },
    getHeaderTitleMonth(date) {
      let year = date.split("-")[0];
      let month = date.split("-")[1];
      if (month === undefined) month = "";
      else {
        month = parseInt(month);
        month = "." + month + "월";
      }
      return year + month;
    },
    dataCheck(data) {
      if (data === null || data === undefined || data === "") return true;
      return false;
    },
    dataCheck_all() {
      let alertText = "";

      if (this.dataCheck(this.userData.mediItem)) {
        alertText = "진료과목을 선택하세요";
      } else if (this.dataCheck(this.userData.mediYn)) {
        alertText = "진료여부를 선택하세요";
      } else if (
        this.userData.mediItem === "기타" &&
        this.dataCheck(this.tfVal)
      ) {
        alertText = "증상을 입력하세요";
      } else if (this.dataCheck(this.userData.hopeDt)) {
        alertText = "상담 희망 날짜를 선택하세요";
      } else if (this.dataCheck(this.ttSelect)) {
        alertText = "상담 희망 시간을 선택하세요";
      } else if (this.dataCheck(this.userData.bookNm)) {
        alertText = "이름을 입력하세요";
      } else if (
        this.dataCheck(this.userData.hpNo) ||
        this.userData.hpNo.length < 10
      ) {
        alertText = "올바른 연락처를 입력하세요";
      } else if (this.userData.pslAgrYn !== "Y") {
        alertText = "개인정보 수집/이용 동의가 필요합니다";
      }
      if (alertText !== "") {
        this.popupActive("입력이 올바르지 않습니다.", alertText);
        return false;
      }
      this.userData.drId = this.$route.params.code;
      this.userData.mediYn === "초진"
        ? (this.userData.mediYn = "N")
        : (this.userData.mediYn = "Y");
      this.userData.mediItem === "기타"
        ? (this.userData.mediItem = this.tfVal)
        : "";
      this.userData.hopeTm = this.timeTable[this.ttSelect];
      this.userData.bookDt = this.userData.bookDt.replaceAll("-", "");
      this.userData.hopeDt = this.userData.hopeDt.replaceAll("-", "");
      this.userData.smsYn !== "Y" ? (this.userData.smsYn = "N") : "";
      return true;
    },
    async addBook() {
      if (!this.dataCheck_all()) return;
      try {
        const param = this.userData;
        await this.ADD_BOOK(param).then(() => {
          this.popupActive("", "신청이 완료되었습니다.", "appointment");
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          console.log("서버요청 실패");
        }
      }
    },
    popupActive(
      title,
      content,
      active = true,
      type = "warn",
      btntext = "확인"
    ) {
      this.SET_POPUP(active);
      this.popupSet.title = title;
      this.popupSet.content = content;
      this.popupSet.popType = type;
      this.popupSet.cancelBtnText = btntext;
    },
    popupClose() {
      window.open("", "_self").close();
    },
    inputCheck() {
      if (this.dataCheck(this.userData.mediItem)) {
        return false;
      } else if (this.dataCheck(this.userData.mediYn)) {
        return false;
      } else if (
        this.userData.mediItem === "기타" &&
        this.dataCheck(this.tfVal)
      ) {
        return false;
      } else if (this.dataCheck(this.userData.hopeDt)) {
        return false;
      } else if (this.dataCheck(this.ttSelect)) {
        return false;
      } else if (this.dataCheck(this.userData.bookNm)) {
        return false;
      } else if (
        this.dataCheck(this.userData.hpNo) ||
        this.userData.hpNo.length < 10
      ) {
        return false;
      } else if (this.userData.pslAgrYn !== "Y") {
        return false;
      }
      return true;
    },
  },
};
</script>
<style scoped>
input,
textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
</style>
<style>
.tab_bb {
  border-bottom: 1px solid lightgray;
}

.tab_bt {
  border-top: 1px solid lightgray;
}

.tab_br {
  border-right: 1px solid lightgray;
}

.borderbox {
  border: 1px solid lightgray;
}
</style>
